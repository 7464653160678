import styles from './index.module.scss'
import {searchFormList} from '@/utils/constant';
import SearchForm from './searchForm.jsx'
import { Form, Button, message } from 'antd';
import { DownOutlined, UpOutlined, SearchOutlined, SyncOutlined} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '@/App'
import { useState, useContext } from 'react';
const Search = (props) => {
  const { isLogin } = useContext(GlobalContext);
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [searchForm] = Form.useForm();
  const renderFormItem = (item) => {
    switch(item.type) {
      case 1:
        return `<Form.Item key={'${item.id}'} name={'${item.field}'}  label={'${item.name}'}>
        <Input placeholder={'${item.name}'} />;
      </Form.Item>`
        
      case 2:
        return `<Select
          placeholder={'${item.name}'}
          // onChange={onGenderChange}
          allowClear
        >
          ${
            item.config?.map(() => (
              '<Option key={i} value={dataItem.value}>{dataItem.key}</Option>'
              ))
          }
        </Select>`
      default:
        return '' 
    }
  };
  
  const onReset = async() => {
    await searchForm.resetFields();
    props.onSearch(searchForm.getFieldsValue())
  };
  const onSearch = () => {
    message.destroy()
    props.onSearch(searchForm.getFieldsValue())
    // if (isLogin) {
    //   props.onSearch(searchForm.getFieldsValue())
    // } else {
    //   // history.push('login')
    //   message.info('请先登录')
    // }
  };
  
  return <div className={styles.searchForm}>
     <Form
        layout={'inline'}
        form={searchForm}
      >
        <div className={ !isShow ? 'searchForm': 'searchFormShow'}>
          <SearchForm />
        </div>
        <div className={ !isShow ? 'searchBtn': 'searchBtnShow'}>
          <Form.Item  className={styles.btnBox}>
            <Button type="primary" onClick={onSearch} icon={<SearchOutlined />}>
              搜索
            </Button>
            <Button htmlType="button" onClick={onReset} icon={<SyncOutlined />}>
              重置
            </Button>
            <Button type="text" onClick={() => setIsShow(!isShow)} icon={isShow ? < UpOutlined />:<DownOutlined />} style={{color: '#1AB394',padding: '4px 5px'}}>
              { 
                isShow ? '收起':'展开'
              } 
            </Button>
          </Form.Item>
        </div>
      </Form>
  </div>
}
export default Search;