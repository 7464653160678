export const SUCCESS_CODE = 0;
export const DEFAULT_SYSTEM_ERROR_MSG = '系统错误，请稍后再试';
export const DEFAULT_SYSTEM_CANCEL_MSG = '操作太频繁，请稍后再试';
export const DEFAULT_RESPONSE_TYPE = 'json';
export const DEFAULT_REQUEST_TYPE = 'application/json';
export const TIMEOUT = 60000 * 5;
export const POST = 'post';
export const GET = 'get';

export const SYSTEM_ERROR_400 = {
  key: 400,
  value: '请求错误'
};

export const SYSTEM_ERROR_401 = {
  key: 401,
  value: '未授权，请重新登录'
};

export const SYSTEM_ERROR_403 = {
  key: 403,
  value: '拒绝访问'
};

export const SYSTEM_ERROR_404 = {
  key: 404,
  value: '请求出错'
};

export const SYSTEM_ERROR_408 = {
  key: 408,
  value: '请求超时'
};

export const SYSTEM_ERROR_500 = {
  key: 500,
  value: '服务器错误'
};

export const SYSTEM_ERROR_501 = {
  key: 501,
  value: '服务未实现'
};

export const SYSTEM_ERROR_502 = {
  key: 502,
  value: '网络错误'
};

export const SYSTEM_ERROR_503 = {
  key: 503,
  value: '服务不可用'
};

export const SYSTEM_ERROR_504 = {
  key: 504,
  value: '网络超时'
};

export const SYSTEM_ERROR_505 = {
  key: 505,
  value: 'HTTP版本不受支持'
};
