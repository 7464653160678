// post、get、postFile
import axios from 'axios';
import { message } from 'antd';
import querystring from 'querystring';
import ENV from '@/config/env';
import { tools } from '@/utils';
import * as CONSTANTS from './constants';

// import { ActionTypes } from '@/reducers/common/actions';
// import store from '@/store';

// 取消重复请求
const pending = [];
const CancelToken = axios.CancelToken;

const instance = axios.create({
  baseURL: ENV.apiHost + ENV.baseApi,
  timeout: CONSTANTS.TIMEOUT,
  responseType: CONSTANTS.DEFAULT_RESPONSE_TYPE,
  withCredentials: true
});

// 移除请求
const removePending = (config) => {
  pending.some((item, index) => {
    // if (item.url === config.url && item.method === config.method) {
    //   item.cancel(CONSTANTS.DEFAULT_SYSTEM_CANCEL_MSG);
    //   pending.splice(index, 1);
    //   return true;
    // }
    // return false;
    return false;
  });
};

// 添加请求拦截器
instance.interceptors.request.use(

  request => {
    // 添加请求头 注入token
    const token = tools.getToken();
    const Authorization = tools.getAuthorization();
    request.withCredentials = true;
    request.headers.common["Authorization"] = !!Authorization ? Authorization:0
    if (token) {
      request.headers.token = token;
    }
    removePending(request);
    request.cancelToken = new CancelToken((c) => {
      pending.push({
        url: request.url,
        method: request.method,
        params: request.params,
        data: request.data,
        cancel: c
      });
    });
    return request;
  },
  error => Promise.reject(error)
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // console.log('-------success-response---------', response);
    removePending(response.config);
    const data = response?.data;
    // if (data?.code === CONSTANTS.SUCCESS_CODE || response?.request?.responseType === 'blob') {
    if (data?.status == 200 ) {
      return response.data;
    } else {
      // return response
      throw data;
    }
  },
  error => {
    const response = error.response;
    console.log('-------error-response---------', response);
    // 根据返回的http状态码做不同的处理
    switch (response?.status) {
      case CONSTANTS.SYSTEM_ERROR_400.key:
        console.error(CONSTANTS.SYSTEM_ERROR_400.value);
        break;
      case CONSTANTS.SYSTEM_ERROR_401.key:
        //登录token 失效，重新登录
        window.location.href = '/auth';

        console.error(CONSTANTS.SYSTEM_ERROR_401.value);
        break;
      case CONSTANTS.SYSTEM_ERROR_403.key:
        console.error(CONSTANTS.SYSTEM_ERROR_403.value);
        break;
      case CONSTANTS.SYSTEM_ERROR_404.key:
        console.error(CONSTANTS.SYSTEM_ERROR_404.value);
        break;
      case CONSTANTS.SYSTEM_ERROR_408.key:
        console.error(CONSTANTS.SYSTEM_ERROR_408.value);
        break;
      case CONSTANTS.SYSTEM_ERROR_500.key:
        console.error(CONSTANTS.SYSTEM_ERROR_500.value);
        break;
      case CONSTANTS.SYSTEM_ERROR_501.key:
        console.error(CONSTANTS.SYSTEM_ERROR_501.value);
        break;
      case CONSTANTS.SYSTEM_ERROR_502.key:
        console.error(CONSTANTS.SYSTEM_ERROR_502.value);
        break;
      case CONSTANTS.SYSTEM_ERROR_503.key:
        console.error(CONSTANTS.SYSTEM_ERROR_503.value);
        break;
      case CONSTANTS.SYSTEM_ERROR_504.key:
        console.error(CONSTANTS.SYSTEM_ERROR_504.value);
        break;
      case CONSTANTS.SYSTEM_ERROR_505.key:
        console.error(CONSTANTS.SYSTEM_ERROR_505.value);
        break;
      default:
        console.error(CONSTANTS.DEFAULT_SYSTEM_ERROR_MSG);
        break;
    }
    message.error(error.msg || error.message);

    return Promise.reject(error || {message: error.message});
  }
);

const ajax = async (config) => {
  // console.log('/auth/bus/query/user/org', config);
  // if (!(window.location.pathname.indexOf('partner') !== -1 && config.url === '/auth/bus/query/user/org')) {
  //   store.dispatch({ type: ActionTypes.SET_SPIN, payload: true});
  // }
  return instance.request(config)
    .then((response) => {
      // setTimeout(() => {
      //   if (!(window.location.pathname.indexOf('partner') !== -1 && config.url === '/auth/bus/query/user/org')) {
      //     store.dispatch({ type: ActionTypes.SET_SPIN, payload: false});
      //   }
      // }, 500);
      if (response) {
        // console.log('this is ', response);
        return response?.data
        return response?.request?.responseType === 'blob' ? response : response?.data?.data;
      }
      return null;
    })
    .catch(error => {
      // if (!(window.location.pathname.indexOf('partner') !== -1 && config.url === '/auth/bus/query/user/org')) {
      //   store.dispatch({ type: ActionTypes.SET_SPIN, payload: false});
      // }
      console.log('---------ajax----', error.msg || error.message);
      // message.error(error.msg || error.message);
      // console.log('err', error);
      // 用户未授权跳转到未授权页面
      if (error.code === 22004 || error.code === 22005 || error.code === 260041) {
        window.location.href = '/nopermission';
      } else {
        return Promise.reject(error);
      }
    });
};

const promise = (config) => {
  return new Promise((resolve, reject) => {
    ajax(config).then(
      (res) => {
        console.log('err', res);

        res ? resolve(res) : reject(res);
      },
      (err) => {
        console.log(err);
        reject(err);
      }
    );
  });
};
export const post = (url, data = {}, params = {}, config) => {
  const newConfig = {
    url: ENV.apiHost + ENV.baseApi + url,
    params: querystring.stringify(params),
    data: JSON.parse(JSON.stringify(data)),
    method: CONSTANTS.POST,
    ...config
  };
  return promise(newConfig);
};

export function POST(url, params = { loadedId: null }, progressCallback) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: ENV.apiHost + ENV.baseApi  + url,
      data: JSON.parse(JSON.stringify(params)),
      timeout: params.loadedId ? 300000 : 50000, // 超时范围在50秒
      responseType: 'json',
      onUploadProgress: (event) => {
        // 如果传递了文件loadedId才返回进度
        if (params.loadedId) {
          const complete = (((event.loaded / event.total) * 100) | 0) + '%';
          progressCallback({
            loadedId: params.loadedId,
            progress: complete
          });
        }
      }
    }).then((res) => {
      if (res.status == 200) {
        // token丢失或过期
        if (res.data && res.data.code === '401') {
          message.info('超时了，马上给您重新登录');
          // wxLogin();
        } else if (res.data && res.data.code === 0) {
          resolve(res.data.data);
        } else {
          const msg = res.data && (res.data.errmsg || res.data.msg || res.data.data);
          if (msg) {
            // message.error(msg)
            reject('nodata');
          } else {
            reject('nodata');
          }
        }
      } else {
        message.error('网络不给力，请检查网络再试哦');
        reject('error');
      }
    });
  });
}

export const get = (url, params = {}, config) => {
  const newConfig = {
    url: ENV.apiHost + ENV.baseApi + url,
    method: CONSTANTS.GET,
    ...config
  };

  if (params) {
    if (typeof params === 'object') {
      let queryArr = Object.keys(params).map((key) => `${key}=${params[key]}`);
      newConfig.url += queryArr.length ? `?${queryArr.join('&')}` : '';
    } else {
      newConfig.url += `?${params}`;
    }
  }

  return promise(newConfig);
};

export const postFile = (url, data = {}) => {
  const newConfig = {
    url: ENV.apiHost + ENV.baseApi + url,
    data: data,
    method: CONSTANTS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return promise(newConfig);
};

export default ajax;
