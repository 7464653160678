import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='3605' label='微信搜索排名' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3605_start' name='rank_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3605_end' name='rank_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3606' name='nickname' label='公众号名称' className ='searchFormItem'><Input placeholder='公众号名称' /></Form.Item>


    
      <Form.Item key='3616' name='realname_type' label='属性' className = 'searchFormItem'>
        <Select
            placeholder='属性'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>公司</Option>
                <Option key='0' value='0'>个人</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='3608' name='type' label='匹配类型' className = 'searchFormItem'>
        <Select
            placeholder='匹配类型'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>完全匹配</Option>
                <Option key='2' value='2'>包含匹配</Option>
                <Option key='3' value='3'>类似匹配</Option>
        </Select>
      </Form.Item>


    
</Fragment>