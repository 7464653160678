const getApiHost = () => {
  let { hostname } = window.location;
  // 本地
  if (hostname === 'localhost') {
    return 'http://api.yinliu.club/';
    // return 'http://47.104.203.91/';
  }
  //测试
  else if (hostname.indexOf('47.99.109.84') > -1) {
    return 'http://47.99.109.84/yibanapp/';
  }
  //开发
  else if (hostname.indexOf('118.178.138.201') > -1) {
    //return 'http://dev.adprm.com/yibanapp/';
    return 'http://118.178.138.201/yibanapp/';
  }
  //预发布
  else if (hostname.indexOf('47.98.136.250') > -1) {
    return 'http://47.98.136.250/yibanapp/';
  }
  //生产
  else if (hostname.indexOf('yiban.eapps.dingtalkcloud.com') > -1) {
    //return 'http://dev.adprm.com/yibanapp/';
    return 'https://yiban.eapps.dingtalkcloud.com/yibanapp/';
  }
  // 生产
  else if (hostname.indexOf('advance.yinliu.club') > -1) {
    return 'https://advance-api.yinliu.club/';
  }
  // 生产
  else if (hostname.indexOf('lms.yinliu.club') > -1) {
    return 'https://advance-api.yinliu.club/';
  }
  // return `https://${hostname}:8084/`;
  return 'http://api.yinliu.club/';
};

export default {
  // router前缀
  basePath: '',
  // api前缀
  baseApi: '',
  apiHost: getApiHost(),
};
